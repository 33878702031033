import { useTranslation } from "@dione/localization";
import React from "react";
import { Tag, TagProps } from "../../../../components/Tag/index";
import {
  BlockIcon,
  CommunityIcon,
  TimerIcon,
  VerifiedIcon,
  VoteIcon,
  RocketIcon,
  CheckmarkCircleIcon,
} from "../../../../components/Svg";

const CoreTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag
      variant="primary"
      style={{ background: "none", width: "fit-content" }}
      outline
      endIcon={<VerifiedIcon width="20px" color="primary" />}
      {...props}
    >
      {t("Core")}
    </Tag>
  );
};

const CommunityTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="failure" outline endIcon={<CommunityIcon width="20px" color="failure" />} {...props}>
      {t("Community")}
    </Tag>
  );
};

const DualTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="primary" outline {...props}>
      {t("Dual")}
    </Tag>
  );
};

const VoteNowTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="success" endIcon={<VoteIcon width="20px" color="background" />} {...props}>
      {t("Vote Now")}
    </Tag>
  );
};

const VotedTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag
      variant="success"
      style={{ background: "none" }}
      outline
      endIcon={<VoteIcon width="20px" color="success" />}
      {...props}
    >
      {t("Voted")}
    </Tag>
  );
};

const SoonTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="warning" endIcon={<TimerIcon width="20px" color="background"  />} {...props}>
      {t("Soon")}
    </Tag>
  );
};

const ClosedTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="failure" endIcon={<BlockIcon width="20px" color="white" />} {...props}>
      {t("Closed")}
    </Tag>
  );
};

const BoostedTag: React.FC<React.PropsWithChildren<TagProps>> = (props) => {
  const { t } = useTranslation();
  return (
    <Tag variant="success" outline endIcon={<RocketIcon width="20px" color="success" />} {...props}>
      {t("Boosted")}
    </Tag>
  );
};

const Tags = {
  CoreTag,
  DualTag,
  VoteNowTag,
  SoonTag,
  ClosedTag,
  CommunityTag,
  BoostedTag,
  VotedTag,
};

export default Tags;
