import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M17.4409 11.2005C16.8639 14.7334 13.8694 17.3651 10.2918 17.4838C6.71411 17.6024 3.55185 15.1748 2.74204 11.688C1.93223 8.20116 3.70091 4.62835 6.96466 3.15809C10.2284 1.68783 14.0763 2.7305 16.1512 5.64739L10.0595 11.7149L7.48679 9.14052" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
