import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.33656 16.4251H5.39073C4.3874 16.4251 3.57323 15.6117 3.57323 14.6076V13.4142C3.57323 12.9326 3.38156 12.4701 3.04073 12.1292L2.19656 11.2851C1.48656 10.5751 1.48656 9.42506 2.19656 8.71506L3.04073 7.87089C3.38156 7.53006 3.57323 7.06839 3.57323 6.58589V5.39256C3.57323 4.38923 4.38656 3.57506 5.39073 3.57506H6.58406C7.06573 3.57506 7.52823 3.38339 7.86906 3.04256L8.71323 2.19839C9.42323 1.48839 10.5732 1.48839 11.2832 2.19839L12.1274 3.04256C12.4682 3.38339 12.9307 3.57506 13.4124 3.57506H14.6057C15.6091 3.57506 16.4232 4.38839 16.4232 5.39256V6.58589C16.4232 7.06756 16.6149 7.53006 16.9557 7.87089L17.7999 8.71506C18.5099 9.42506 18.5099 10.5751 17.7999 11.2851L16.9557 12.1292C16.6149 12.4701 16.4232 12.9326 16.4232 13.4142V14.6076C16.4232 15.6109 15.6099 16.4251 14.6057 16.4251H13.4124C12.9307 16.4251 12.4682 16.6167 12.1274 16.9576L11.2832 17.8017C10.5732 18.5117 9.42323 18.5117 8.71323 17.8017L7.33656 16.4251Z" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.5 12.5L12.5 7.5" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.7088 7.49996C7.5938 7.49996 7.50047 7.59329 7.5013 7.70829C7.5013 7.82329 7.59464 7.91663 7.70964 7.91663C7.82464 7.91663 7.91797 7.82329 7.91797 7.70829C7.91797 7.59329 7.82464 7.49996 7.7088 7.49996" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.2908 12.0833C12.1758 12.0833 12.0825 12.1767 12.0833 12.2917C12.0833 12.4067 12.1767 12.5 12.2917 12.5C12.4067 12.5 12.5 12.4067 12.5 12.2917C12.5 12.1767 12.4067 12.0833 12.2908 12.0833" stroke="currentColor" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
